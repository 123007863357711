import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import Layout from "../components/Layout";

export const GalleryPageTemplate = ({ heading, clImages }) => {
  return (
    <div className="content">
      <div
        className="full-width-image margin-top-0"
        style={{
          backgroundImage: `url('/img/jumbotron.jpg')`,
          backgroundPosition: `top left`,
          backgroundAttachment: `fixed`,
          height: "90vh",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            height: "150px",
            lineHeight: "1",
            justifyContent: "space-around",
            alignItems: "left",
            flexDirection: "column",
          }}
        >
          <h1
            className="has-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-1-widescreen"
            style={{
              boxShadow:
                "rgb(255, 68, 0) 0.5rem 0px 0px, rgb(255, 68, 0) -0.5rem 0px 0px",
              backgroundColor: "rgb(255, 68, 0)",
              color: "white",
              lineHeight: "1",
              padding: "0.25em",
            }}
          >
            {heading}
          </h1>
        </div>
      </div>
      <section className="section section--gradient">
        <div className="container">
          <div className="section">
            <div className="columns">
              <div className="column is-10 is-offset-1">
                {/* ────────────────────────────────────────────────────────────────────────────────  */}
                <div className="tile is-ancestor">
                  <div className="tile is-vertical">
                    <div className="tile is-parent">
                      <article className="tile is-child">
                        {clImages.map((image, index) => (
                          <div
                            key={`${index}-cl`}
                            style={{
                              marginBottom: 10,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <img
                              src={image.node.secure_url}
                              style={{ maxWidth: 700 }}
                            />
                          </div>
                        ))}
                      </article>
                    </div>
                  </div>
                </div>
                {/* ────────────────────────────────────────────────────────────────────────────────  */}
                <div className="columns" style={{ marginTop: 50 }}>
                  <div className="column is-12 has-text-centered">
                    <Link className="btn" to="/blog">
                      Vea mas en nuestro blog!
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

GalleryPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  heading: PropTypes.string,
};

const GalleryPage = ({ data }) => {
  const clImages = data.allCloudinaryMedia.edges;

  return (
    <Layout>
      <GalleryPageTemplate
        clImages={clImages}
        heading="Algunas de nuestras producciones"
      />
    </Layout>
  );
};

GalleryPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default GalleryPage;

export const GalleryPageQuery = graphql`
  query CloudinaryImages {
    allCloudinaryMedia {
      edges {
        node {
          secure_url
        }
      }
    }
  }
`;
